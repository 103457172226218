<template>
  <div class="navbarNew helperBottomLine">
    <div class="navbarNew__logo">
      <img
          src="https://recursos.digital.nl.gob.mx/assets/img/logos/logo_genl_blanco.svg"
          @click="$router.push('/tramites')"
      />
    </div>
    <div class="caja_banner">
      <div class="tiene_logo">
          <img decoding="async" src="http://recursos.digital.nl.gob.mx/assets/img/logos/logo_estado_vertical_blanco.svg" alt="">
          <h2>Registro Estatal de Contribuyentes</h2>
          <!-- <h4>Gobierno del estado de Nuevo León</h4> -->
      </div>
      <img decoding="async" src="http://recursos.digital.nl.gob.mx/assets/img/fondos/cerro_mitras.png" alt="" class="banner_cover img-responsive">
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style lang="scss">
.navbarNew {
  padding-top: 60px;
  &__logo {
    padding: 11px 30px 11px 30px;
    position: fixed;
    z-index: 101;
    width: 100%;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    background: var(--color_secundario);
    img {
      cursor: pointer;
      width: 316px;
      height: 39px;
    }
  }
  &__banner {
    padding-top: 65px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    padding: 85px 0;
    min-height: 55vh;
    background-image: url("~@/assets/img/front_principal.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-position-y: 0;
    padding: 132px 0;
    &--title {
      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
      width: 60%;
      h1 {
        font-family: Open Sans;
        font-size: 3.2rem;
        font-weight: 800;
        color: #fff;
        max-width: 90%;
        border-bottom: none;
        margin: 0;
      }
    }
  }
}

.helperBottomLine {
  border-bottom-style: solid;
  border-bottom-width: 0.001rem;
  color: #f4f4f4;
}
</style>
