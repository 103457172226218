<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
          :class="{ 'modal-scrollable': errorData.isPrivacy }"
                role="dialog"
                aria-labelledby="modalTitle"
                aria-describedby="modalDescription"
                v-bind="errorData"
        >
      <header
        class="modal-header"
        id="modalTitle"
      >
          <slot name="header" >
        <p class="header-text" v-html="errorData.title">{{errorData.title}}</p>
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
        >
            x
        </button>
          </slot>
      </header>
      <section
        class="modal-body"
        :class="{ 'modal-body-scrollable': errorData.isPrivacy }"
        id="modalDescription"
      >
          <slot name="body" >
        <p class="body-text"
          :class="{ 'body-text-scrollable': errorData.isPrivacy }"
          v-html="errorData.description">{{errorData.description}}</p>
          </slot>
      </section>

      <footer>
          <slot name="footer">
        <button @click="close" id="footer-button" >Ok</button>
          </slot>
      </footer>

        </div>
    </div>
  </transition>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
      name: 'modal',
      props: {
          error: {
        type: Object,
        default () {
            return{
          title: '',
          description: '',
          isPrivacy: false
            }
        }
          }
      },
      data() {
          return {
        errorData: this.error
          }
      },
      watch: {
          error(newError) {
        this.errorData = newError;
          }
      },
      methods: {
          getClassModalScrollable() {
        return {
            'modal-scrollable': true
        }
          },
          getClassModalBodyScrollable() {
        return {
            'modal-body-scrollable': true
        }
          },
          close() {
        this.$emit('close');
          },
      },
    };
</script>

<style>
    .modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border-width: 2px;
    }

    .modal-scrollable {
	height: 100%;		
    }
    
    .modal {
	background: #FFFFFF;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	width: 40%;
	border-radius: 12px;	
    }

    .modal-header {
	padding: 15px;
	display: flex;
    }

    .modal-header {
	border-bottom: 1px solid #eeeeee;
	color: #4AAE9B;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
    }

    .header-text {
	padding: 10px;
	margin: 0;
    }

    .modal-body-scrollable {
	padding: 0px 50px 50px 50px !important;
	height: inherit;
	overflow-y: auto;
    }
    
    .modal-body {
	position: relative;
	padding: 50px;	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    }

    .body-text {
	padding: 10px;
	margin-top: 0;
    }

    .body-text-scrollable {
	height: inherit;
    }    

    .btn-close {
	border: none;
	font-size: 20px;
	padding: 10px;
	cursor: pointer;
	font-weight: bold;
	color: #4AAE9B;
	background: transparent;
    }

    .btn-green {
	color: white;
	background: #4AAE9B;
	border: 1px solid #4AAE9B;
	border-radius: 2px;
    }

    footer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 20px;
    }

    footer > #footer-button {
	background-color: var(--color_secundario);
	color: #FFFFFF;
	padding: 10px 20px;
	border: none;
	cursor: pointer;
	border-radius: 4px;
    }
</style>
