<template>
  <div id="app">
    <portal-target name="semantic-ui-vue" />
    <NavBar />
    <router-view />
    <Footer v-show="!this.getIsLoading().isLoading" />
    <Loader v-show="this.getIsLoading().isLoading" :message="this.getIsLoading().message" />
    <Modal v-show="getErrorModalData().show" :error="getErrorModalData().error" @close="closeModal" />
    <ModalUpdateApp v-show="showUpdateModal" @reload="reloadApp" />
    <ModalAdviseApp v-show="showAdviseModal"  @close="closeAdviseModal"/>
  </div>
</template>

<script>
import Loader from './components/Loader.vue';
import { mapGetters, mapActions } from 'vuex';
import { refreshPageMixin } from '@/mixin/automaticRefresh.mixin';
import NavBar from './components/shared/NavBar';
import Modal from './components/shared/Modal.vue';
import ModalUpdateApp from './components/shared/ModalUpdateApp.vue';
import { tramites } from '@/shared/constants';
import Footer from './components/shared/Footer';
// import ModalAdviseApp from "@/components/shared/ModalAdvise.vue";

export default {
  components: {
    // ModalAdviseApp,
    NavBar,
    Loader,
    Modal,
    ModalUpdateApp,
    Footer
  },
  mixins: [refreshPageMixin],
  data: function() {
    return {
      menuItems: tramites,
      showUpdateModal: false,
      showAdviseModal: true
    };
  },
  watch: {
    hashChanged(newVal) {
      if (newVal) {
        this.showUpdateModal = true;
      }
    }
  },
  mounted() {
    if (this.getAppVersion != 'DEV') {
      //this.initVersionCheck(`${location.origin}/version.json`); TODO uncomment on prod
    }
  },
  computed: {
    getAppVersion() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    ...mapGetters({
      getIsLoading: 'getIsLoading',
      getErrorModalData: 'getErrorModalData'
    }),
    ...mapActions({
      showErrorModal: 'saveShowErrorModal'
    }),
    /**
     * Closes the error modal by setting the showError function to false
     *
     * @return void
     */
    closeModal() {
      this.showErrorModal({ show: false });
    },
    closeAdviseModal() {
      this.showAdviseModal = false;
    },
    navigateToService(route) {
      this.$router.push({ path: `/` });
      this.$router.push({ path: `/tramites/${route}` });
    }
  }
};
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.wizard-icon {
  font-style: normal !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.modal{
  position: unset !important;
  height: unset !important;
}
</style>
