<template>
  <div class="ui container">
    <div class="ui grid">
      <div class="sixteen wide column">
        <div class="home">
          <template v-if="arePreackAndFlowLoaded">
            <Header :title="getTitle"></Header>
            <Timeline :flowData="flowData" @updatedModules="handleUpdatedModules" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /srwc
import { mapGetters, mapActions } from 'vuex';
import Timeline from '@/components/Timeline.vue';
import { tramites } from '@/shared/constants';
import Header from '@/components/shared/Header.vue';

export default {
  mounted() {
    this.initTransactionData(this.$route.params.tipoTramite);
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      menuOptions: [
        {
          option_title: this.$t('rec.title'),
          option_description: this.$t('rec.description'),
          ruta: 'registro',
          id: 1
        },
        {
          option_title: this.$t('aao.title'),
          option_description: this.$t('aao.description'),
          ruta: 'aviso-aumento-obligaciones',
          id: 2
        },
        {
          option_title: this.$t('ado.title'),
          ruta: 'aviso-disminucion-obligaciones',
          option_description: this.$t('ado.description'),
          id: 3
        },
        {
          option_title: this.$t('acdf.title'),
          option_description: this.$t('acdf.description'),
          ruta: 'aviso-cambio-domicilio',
          id: 4
        },
        {
          option_title: this.$t('aae.title'),
          option_description: this.$t('aae.description'),
          ruta: 'aviso-apertura-establecimiento',
          id: 5
        },
        {
          option_title: this.$t('ace.title'),
          option_description: this.$t('ace.description'),
          ruta: 'aviso-cierre-establecimiento',
          id: 6
        },
        {
          option_title: this.$t('cdrs.title'),
          option_description: this.$t('cdrs.description'),
          ruta: 'aviso-cambio-denominacion',
          id: 7
        },
        {
          option_title: this.$t('crl.title'),
          option_description: this.$t('crl.description'),
          ruta: 'aviso-cambio-representante-legal',
          id: 8
        },
        {
          option_title: this.$t('sa.title'),
          option_description: this.$t('sa.description'),
          ruta: 'aviso-suspension-actividades',
          id: 9
        },
        {
          option_title: this.$t('ra.title'),
          option_description: this.$t('ra.description'),
          ruta: 'aviso-reanudacion-actividades',
          id: 10
        },
        {
          option_title: this.$t('il.title'),
          option_description: this.$t('il.description'),
          ruta: 'aviso-inicio-liquidacion',
          id: 11
        },
        {
          option_title: this.$t('as.title'),
          option_description: this.$t('as.description'),
          ruta: 'aviso-apertura-sucesion',
          id: 12
        },
        {
          option_title: this.$t('brec.title'),
          option_description: this.$t('brec.description'),
          ruta: 'aviso-baja-rec',
          id: 13
        }
      ],
      flowData: {},
      modules: {},
      isFlowDataLoaded: false,
      isPreackLoaded: false
    };
  },
  methods: {
    showServerError() {
      this.saveIsLoading({ isLoading: false });
      this.showErrorModal({
        show: true,
        error: { title: 'Error', description: this.$t('server.error') }
      });
      this.$router.push('/');
    },
    ...mapActions({
      saveCurrentAlias: 'saveCurrentAlias',
      saveInitialModuleByAlias: 'saveInitialModuleByAlias',
      loadFlowBuilderData: 'loadFlowBuilderData',
      generatePreack: 'generatePreack',
      showErrorModal: 'saveShowErrorModal',
      saveIsLoading: 'saveisLoading'
    }),
    ...mapGetters({
      getCurrentAlias: 'getCurrentAlias',
      getCurrentModule: 'getCurrentModule',
      LoadFlowState: 'LoadFlowState',
      getPreacuse: 'getPreacuse',
      isDisableRouteLeaveDialog: 'getIsDisableRouteLeaveDialog'
    }),
    /**
     * handles the store of the <currentModuleStore>.data
     * this function receives an object with just the properties
     * of the component or necessary data to be attached to the data property
     * in the submodule for example datos_identificacion -> data.
     *
     * @param {} newData your object from your component @data event or other info
     *                   that needs to be attached to the data proeprty of the module in order
     *                   to be processed.
     * DEPRECATED : REMOVE AFTER FINISH REC-73
     * @return void
     *
     */
    handleComponentData(newData) {
      let data = this.$store.state[this.getCurrentModule()].data;
      if (data && data.length) {
        data = { ...data, ...newData };
        data = JSON.parse(data);
      } else {
        newData.preack_id = this.getPreacuse().data.id;
        data = newData;
      }
      this.$store.dispatch(`${this.getCurrentModule()}/setData`, data);
    },
    /**
     * Inits the flow data of the transaction flow and sets on the store
     * the current module and current alias of the transaction that the user
     * is going to be worked with.
     *
     * @param string transactionType
     * @return void
     */
    initTransactionData(transactionType) {
      this.resetLoadedStatus(this.saveIsLoading({ isLoading: true }));
      this.saveCurrentAlias(transactionType);
      this.saveInitialModuleByAlias(transactionType);
      this.loadFlowBuilderData(this.getCurrentAlias())
          .then(success => {
            this.isFlowDataLoaded = success;
            this.flowData = this.LoadFlowState();

            if (this.$route.params.tipoTramite === tramites.registro.ruta) {
              this.flowData.data.modules.splice(4, 2);
            }
          })
          .catch(error => {
            if (error && (error.status === 500 || error.status === 'fail')) {
              this.showServerError();
            }
          });
      this.initPreackData();
    },
    /**
     * Calls the preack service to be used on the initTransactionData
     * funtion.
     *
     * @return void
     */
    initPreackData() {
      this.generatePreack(this.getCurrentAlias())
          .then(() => {
            this.isPreackLoaded = true;
          })
          .catch(() => {
            this.showServerError();
          });
    },
    resetLoadedStatus() {
      this.isFlowDataLoaded = false;
      this.isPreackLoaded = false;
    },
    /**
     * Handles the updated modules by timeline depending if the person is "moral" or "fisica"
     * the modules change.
     */
    handleUpdatedModules(modules) {
      this.modules = modules;
    },
    /**
     * Diisplays a dialog and redirect the user to the next route depending the answer they gave
     * handled by a promise resolution... then handles the continue to next route behavior and the
     * catch mantains the user in the current route if they clicked close on the dialog.
     *
     * @param next object ro redirect the user or stop it from leave a route passed from the beforeRouteLeave
     * function.
     *
     * @return void
     */
    beforeLeaveDialog(next) {
      let options = {
          okText: this.$t('validate.taxpayer.continue'),
          cancelText: this.$t('general.form.cancel'),
      };
      this.$dialog
          .confirm(this.$t('general.form.ask_before_redirect'), options)
          .then(function() {
            next();
          })
          .catch(function() {
            next(false);
          });
    },
    /**
     * Hanles the logic of when to display the before leave route dialog
     *
     * @param next object ro redirect the user or stop it from leave a route passed from the beforeRouteLeave
     * function.
     *
     * @return void
     */
    handleRouteLeave(next) {
      if (
          this.modules &&
          this.modules.length &&
          this.getCurrentModule() !== this.modules[this.modules.length - 1].alias
      ) {
        this.beforeLeaveDialog(next);
      } else {
        if (this.isDisableRouteLeaveDialog()) {
          next();
        } else {
          this.beforeLeaveDialog(next);
        }
      }
    }
  },
  computed: {
    getTitle() {
      return this.menuOptions.find(e => e.ruta === this.$route.params.tipoTramite).option_title; //eslint-disable-line
    },
    arePreackAndFlowLoaded() {
      return this.isFlowDataLoaded && this.isPreackLoaded;
    }
  },
  watch: {
    arePreackAndFlowLoaded(areLoaded) {
      if (areLoaded) {
        this.saveIsLoading({ isLoading: false });
      }
    }
  },
  components: {
    Timeline,
    Header
  },
  /**
   * Router guard to render other transaction types.
   */
  beforeRouteUpdate(to, from, next) {
    console.log(from, next);
    if (to.params.tipoTramite && to.params.tipoTramite.length) {
      //TODO:  change status variables to hide the main route
      this.initTransactionData(to.params.tipoTramite);
    }
  },
  /**
   * When you attemt to leave any route this is fired.
   */
  beforeRouteLeave(to, from, next) {
    this.handleRouteLeave(next);
  }
};
</script>
<style>
.wizard-icon-container{
  background-color: var(--color_secundario) !important;
}
.wizard-progress-bar{
  background-color: var(--color_secundario) !important;
}
.wizard-icon-circle.md.checked{
  background-color: var(--color_secundario) !important;
  border-color: var(--color_secundario) !important;
}
.stepTitle.active{
  color: var(--color_secundario) !important;
}
.wizard-btn{
  background-color: var(--color_secundario) !important;
  border-color: var(--color_secundario) !important;
}
.dg-btn--cancel{
  background-color: var(--color_secundario) !important;
}
.dg-pull-right{
  color: var(--color_secundario) !important;
  border-color: var(--color_secundario) !important;
}
</style>
