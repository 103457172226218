<template>
  <form-wizard
    color="#20bac3"
    title
    ref="formWizard"
    subtitle
    step-size="xs"
    :next-button-text="$t('timeline.btn.next')"
    :finish-button-text="$t('timeline.btn.finish')"
    :back-button-text="$t('timeline.btn.back')"
    class="wizard-progress-with-circle wizard-nav-pills wizard-progress-with-circle"
  >
    <!-- Using this to avoid click on pills -->
    <template slot="step" slot-scope="props">
      <wizard-step
        :tab="props.tab"
        :transition="props.transition"
        :key="props.tab.title"
        :index="props.index"
      ></wizard-step>
    </template>

    <tab-content title="Datos de Identificacion" :before-change="() => validateComponents([], 'datos_identificacion')">
      <h2 class="ui header center aligned">{{ $t('identification_data.form.title') }}</h2>
      <div v-if="flow.length > 0">
        <h2 class="ui header center aligned">Paso 1 de {{ flowData.data.modules.length + 1 }}</h2>
      </div>
      <h4>{{ $t('identification_data.form.description') }}</h4>
      <h5>{{ $t('general.form.description_required_fields') }}</h5>
      <div>
        <Persona @emitRfc="loadFlow" :readOnlyMode="blockRfcFields" />
      </div>

      <div v-if="flow">
        <div v-if="getCurrentAlias() !== 'rec' && tipoPersona.length > 0">
          <NoticesExtraData
            ref="fecha_aviso"
            @data="mergePartialData"
            :readOnlyMode="noticeMovementDateReadOnly || readOnlyMode"
          />
        </div>

        <div v-if="tipoPersona === 'moral'">
          <personaMoral :readOnlyMode="readOnlyMode" ref="persona_moral" @data="mergePartialData" />
        </div>
        <div v-if="tipoPersona === 'both'">
          <personaFisica
            :readOnlyMode="readOnlyMode"
            ref="persona_fisica"
            @data="mergePartialData"
            @setCurp="setCurp"
          />
        </div>
      </div>
    </tab-content>
    <template v-if="flow">
      <tab-content
        v-for="(module, index) in flow"
        :key="index"
        :title="module.name"
        :before-change="() => validateComponents(module.components, module.alias)"
      >
        <div class="col-md-12 body-form">
          <div v-for="component in module.components" :key="component.name">
            <div v-if="component.alias === 'crear_representante'">
              <h2 class="ui header center aligned">
                {{
                  getCurrentAlias() === 'acrl'
                    ? $t('new_legal_representative.form.title')
                    : $t('legal_representative.form.title')
                }}
              </h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <representante
                :tipoPersona="tipoPersona"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                :rfcPersona="rfc"
                :curpPersona="curp"
                @data="mergePartialData"
                @setHasLegalRepresentant="setHasLegalRepresentant"
                :enableSkipStep="getCurrentAlias() !== 'acrl'"
              />
            </div>
            <div v-if="component.alias === 'agregar_acta_contitutiva'">
              <h2 class="ui header center aligned">{{ $t('book.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <actaConstitutiva :readOnlyMode="readOnlyMode" :ref="component.alias" @data="mergePartialData" />
            </div>
            <div v-if="component.alias === 'agregar_domicilio'">
              <h2 class="ui header center aligned">{{ $t('taxresidence.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <DomicilioFiscal
                :description="$t('taxresidence.form.title')"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                @data="mergePartialData"
              />
            </div>

            <div v-if="component.alias === 'change_tax_residence'">
              <h2 class="ui header center aligned">{{ $t('change_tax_address.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <DomicilioFiscal
                :description="$t('change_tax_address.form.description')"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                @data="mergePartialData"
              />
            </div>

            <div v-if="component.alias === 'local_opening'">
              <h2 class="ui header center aligned">{{ $t('establishment_opening.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <DomicilioFiscal
                :description="$t('establishment_opening.form.description')"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                @data="mergePartialData"
              />
            </div>

            <div v-if="component.alias === 'close_local'">
              <h2 class="ui header center aligned">{{ $t('establishment_closure.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <DomicilioFiscal
                :description="$t('establishment_closure.form.description')"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                @data="mergePartialData"
              />
            </div>

            <div v-if="component.alias === 'medios_contacto'">
              <h2 class="ui header center aligned">{{ $t('meansofcontact.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <MediosContacto :readOnlyMode="readOnlyMode" :ref="component.alias" @data="mergePartialData" />
            </div>

            <div v-if="component.alias === 'solicitud_inscripcion'">
              <h2 class="ui header center aligned">{{ $t('inscription_request.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <SolicitudInscripcion
                :constitutiveDate="getCompanyConstitutionDate"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                @data="mergePartialData"
                @setHasEnvironmentalTax="setHasEnvironmentalTax"
                @setHasISHRTax="setHasISHRTax"
              />
            </div>

            <div v-if="component.alias === 'unsubscribe'">
              <h2 class="ui header center aligned">{{ $t('unsubscribe.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <BajaRegistro
                :tipoPersona="tipoPersona"
                :readOnlyMode="readOnlyMode"
                :ref="component.alias"
                @data="setMergeData"
              />
            </div>

            <div v-if="component.alias === 'add_obligation'">
              <h2 class="ui header center aligned">{{ $t('tax_increase.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <AumentoObligacion
                  :readOnlyMode="readOnlyMode"
                  :ref="component.alias"
                  @data="mergePartialData"
                  @setHasEnvironmentalTax="setHasEnvironmentalTax"
                  @setHasISHRTax="setHasISHRTax"
              />
            </div>

            <div v-if="component.alias === 'reduce_obligation'">
              <h2 class="ui header center aligned">{{ $t('tax_decrease.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <DisminucionObligacion
                  :readOnlyMode="readOnlyMode"
                  :ref="component.alias"
                  @data="mergePartialData"
                  @setHasEnvironmentalTax="setHasEnvironmentalTax"
                  @setHasISHRTax="setHasISHRTax"
              />
            </div>

            <div v-if="component.alias === 'agregar_comercio'">
              <h2 class="ui header center aligned">{{ $t('preponderant_activity.form.subtitle') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <h3 class="ui header center aligned module-description">
                {{ $t('preponderant_activity.form.subtitle.description') }}
              </h3>
              <ActividadesEconomicas @data="mergePartialData" :readOnlyMode="readOnlyMode" :ref="component.alias" />
            </div>

            <div v-if="component.alias === 'preview'">
              <h2 class="ui header center aligned">{{ $t('preview.overview.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <VistaPrevia :currentModule="getCurrentModule()" :ref="component.alias" :tipoPersona="tipoPersona" />
            </div>

            <div v-if="component.alias === 'datos_adjuntos'">
              <h2 class="ui header center aligned">{{ $t('attachments.form.title') }}</h2>
              <h2 class="ui header center aligned">Paso {{ index + 2 }} de {{ flowData.data.modules.length + 1 }}</h2>
              <DatosAdjuntos
                :readOnlyMode="readOnlyMode"
                :tipoPersona="tipoPersona"
                :hasLegalRepresentant="hasLegalRepresentant"
                :hasEnvironmentalTax="hasEnvironmentalTax"
                :hasISHRTax="hasISHRTax"
                :ref="component.alias"
                :preackId="getPreackId"
                @data="mergePartialData"
              />
            </div>
          </div>
        </div>
      </tab-content>
    </template>
    <br />
    <!-- Add validtion to know if you are on the last step to hide next button -->
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <wizard-button
          @click.native="
            props.prevTab();
            setPreviousModule(getCurrentModule());
          "
          :disabled="setDisabled"
          class="wizard-footer-right"
          :style="props.fillButtonStyle"
          >{{ $t('timeline.btn.back') }}</wizard-button
        >
      </div>
      <div class="wizard-footer-right">
        <wizard-button
          @click.native="props.nextTab()"
          ref="nextTab"
          class="wizard-footer-right"
          :style="props.fillButtonStyle"
          >{{ $t('timeline.btn.next') }}</wizard-button
        >
      </div>
    </template>
  </form-wizard>
</template>

<script>
import VueFormWizard from 'vue-form-wizard';
import Vue from 'vue';
import Persona from './DatosIdentificacion/TipoPersona';
import representante from './RepresentanteLegal/RepresentanteLegal';
import personaFisica from './DatosIdentificacion/PersonaFisica';
import personaMoral from './DatosIdentificacion/PersonaMoral';
import actaConstitutiva from '@/components/DocumentoProtocolizado/DocumentoProtocolizado.vue';
import DomicilioFiscal from '@/components/DomicilioFiscal/DomicilioFiscal.vue';
import NoticesExtraData from '@/components/shared/NoticesExtraData.vue';
import ActividadesEconomicas from './ActividadesEconomicas/ActividadesEconomicas.vue';

/**
 * REC LITE COMPONENTS
 */
import DatosAdjuntos from './DatosAdjuntos.vue';
import MediosContacto from '@/components/MediosContacto/MediosContacto.vue';
import SolicitudInscripcion from '@/components/SolicitudInscripcion/SolicitudInscripcion.vue';
import BajaRegistro from '@/components/BajaRegistro/BajaRegistro.vue';
import AumentoObligacion from '@/components/Obligaciones/AumentoObligacion.vue';
import DisminucionObligacion from '@/components/Obligaciones/DisminucionObligacion.vue';
import VistaPrevia from '@/components/VistaPrevia/VistaPrevia.vue';

import { mapActions, mapGetters } from 'vuex';

Vue.use(VueFormWizard);

// TODO : Refactorizar Timeline

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Timeline',
  created() {
    this.setDisableRouteLeaveDialog({ disabled: false });
    if (this.$route.params.tipoTramite.length > 0) {
      window.onbeforeunload = () => {
        return '';
      };
    }
    this.currentModule = this.getCurrentModule();
    this.readOnlyMode = this.$store.state[this.getCurrentModule()].isSaved;
  },
  data() {
    return {
      flow: [],
      blockRfcFields: false,
      tipoPersona: '',
      modules: [],
      hideButton: true,
      currentModule: '',
      readOnlyMode: false,
      noticeMovementDateReadOnly: false,
      runValidationInComponents: false,
      hasLegalRepresentant: true,
      hasEnvironmentalTax: false,
      hasISHRTax: false,
      rfc: '',
      curp: '',
      setDisabled: false
    };
  },
  props: {
    flowData: Object
  },
  methods: {
    /**
     * Function used on the ":before-change" form-wizard component prop
     * To validate the components of a module.
     *
     * @return boolean value to know if the form-wizard should proceed to next step.
     */
    validateComponents(components, moduleName) {
      return new Promise((resolve, reject) => {
        if (moduleName === 'datos_identificacion') {
          return this.saveIdenticationDataModule()
            .then(success => {
              resolve(success);
            })
            .catch(failed => {
              reject(failed);
            });
        } else if (moduleName === 'datos_adjuntos') {

          if (this.datosAdjuntosGetIsSaved()) {
            resolve(this.datosAdjuntosGetIsSaved());
          } else {
            return this.saveDatosAdjuntosModule()
            .then(success => {
              this.setNextModule('datos_adjuntos');
              this.setDatosAdjuntosIsSaved(true)
              resolve(success);
            })
            .catch(failed => {
              reject(failed);
            });
          }

        } else {
          let isModuleValid = components.every(component => this.validateSingleComponent(component.alias));
          if (isModuleValid) {
            if (moduleName === 'vista_previa') {
              this.redirectToSuccess(moduleName);
            } else {
              this.$store.dispatch(`${this.getCurrentModule()}/validator`, isModuleValid);
              this.saveData()
                .then(success => {
                  resolve(success)
                })
                .catch(failed => reject(failed));
              }
          } else {
            reject(false);
          }
        }
      });
    },
    /**
     * Validates a single component by using the ref included on each component inside the timeline
     *
     * @param string refName a string containing the reference name of the component to test if is valid.
     * @return boolean returns if the component <validatedForm()> function is valid.
     */
    validateSingleComponent(refName) {
      let refToValidate = this.$refs[refName];
      if (refToValidate && refToValidate.validateForm) {
        return refToValidate.validateForm();
      } else {
        return refToValidate[0].validateForm();
      }
    },
    dryValidateSingleComponent(refName) {
      let refToValidate =
        this.$refs[refName] && this.$refs[refName].validateForm ? this.$refs[refName] : this.$refs[refName][0];
      refToValidate.$v.$reset();
      refToValidate.$v.$touch();
    },
    async validateDatosAdjuntosComponent(refName) {
      const refToValidate = this.$refs[refName];
      let isValid = false;
      if (refToValidate && refToValidate.validateForm) {
        isValid = await refToValidate.validateForm();
      } else {
        isValid = await refToValidate[0].validateForm();
      }

      return new Promise((resolve, reject) => {
        isValid ? resolve(isValid) : reject(false);
      });
    },
    /**
     * Merges the data of each component in a single object and is assigne
     * to the data property of the current sub store module.
     *
     * @param {} newData the object containing the partial data to be merged.
     * @return void
     */
    mergePartialData(newData) {
      let data = this.$store.state[this.getCurrentModule()].data;
      newData.preack_id = this.getPreackId !== newData.preack_id ? this.getPreackId : newData.preack_id;
      if (data) {
        data = { ...data, ...newData };
      } else {
        data = newData;
      }
      this.$store.dispatch(`${this.getCurrentModule()}/setData`, data);
    },
    setMergeData(newData) {
      this.$store.dispatch(`${this.getCurrentModule()}/setData`, newData);
    },
    runComponentsDryalidation(components = []) {
      components.map(component => this.dryValidateSingleComponent(component));
    },
    /**
     * Calls validateSingleComponent to test either personaFisica.vue component
     * or PersonaMoral.vue component depending on the parameter <tipoPersona>
     *
     * @return boolean identification data succesfully saved.
     */
    saveIdenticationDataModule() {
      let components = this.getCurrentAlias() !== 'rec' ? ['fecha_aviso'] : [];
      if (this.tipoPersona === 'moral') {
        components.push('persona_moral');
      } else {
        components.push('persona_fisica');
      }
      this.runComponentsDryalidation(components);
      return new Promise((resolve, reject) => {
        const isValid = components.every(component => this.validateSingleComponent(component));
        if (isValid) {
          // TODO: Move this to his own function
          this.$store.dispatch(`${this.getCurrentModule()}/validator`, isValid);
          this.saveData()
            .then(success => resolve(success))
            .catch(failed => reject(failed));
        } else {
          reject(false);
        }
      });
    },
    saveDatosAdjuntosModule() {
      let refName = 'datos_adjuntos';
      return new Promise((resolve, reject) => {
        this.validateDatosAdjuntosComponent(refName)
          .then(isValid => {
            resolve(isValid);
            this.$store.dispatch(`${this.getCurrentModule()}/validator`, isValid);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    saveData() {
      this.saveIsLoading({ isLoading: true, message: 'Guardando...' });
      return new Promise((resolve, reject) => {
        this.saveCurrentModuleData()
          .then(success => {
            this.saveIsLoading({ isLoading: false });
            resolve(success);
          })
          .catch(failed => {
            this.saveIsLoading({ isLoading: false });
            this.showErrorModal({
              show: true,
              error: { title: 'Error', description: this.$t('server.error') }
            });
            reject(failed);
          });
      });
    },
    //TODO: change to checkRfc
    loadFlow(rfcObj) {
      if (rfcObj.rfc === rfcObj.rfcConfirmation) {
        if (rfcObj.rfcConfirmation.length === 13) {
          this.tipoPersona = 'both';
        } else if (rfcObj.rfcConfirmation.length === 12) {
          this.tipoPersona = 'moral';
        }
        this.rfc = rfcObj.rfc;
        this.saveTipoPersona(this.tipoPersona);
        this.filterFlowModules();
        this.mergePartialData(rfcObj);
        this.blockRfcFields = true;
      }
    },
    /**
     * Removes modules if the type of person is "fisica" and the module
     * is required just for "moral" persons the module is removed.
     */
    filterFlowModules() {
      if (this.flowData.data && this.flowData.data.modules) this.modules = this.flowData.data.modules;
      for (let index = 0; index < this.modules.length; index++) {
        if (this.tipoPersona === 'both' && this.modules[index].required === 'moral') this.modules.splice(index, 1);
      }
      this.$emit('updatedModules', this.modules);
      this.flow = this.modules;
    },
    loadValidated(newValidated) {
      if (newValidated) {
        this.hideButton = false;
      } else {
        this.hideButton = true;
      }
    },
    ...mapActions({
      saveCurrentModuleData: 'saveCurrentModuleData',
      setPrevModule: 'setPreviousModule',
      saveTipoPersona: 'saveTipoPersona',
      saveIsLoading: 'saveisLoading',
      showErrorModal: 'saveShowErrorModal',
      setDisableRouteLeaveDialog: 'setDisableRouteLeaveDialog',
      setNextModule: 'setNextModule',
      setDatosAdjuntosIsSaved: 'datos_adjuntos/setIsSaved',
    }),
    ...mapGetters({
      getCurrentModule: 'getCurrentModule',
      getPreacuse: 'getPreacuse',
      getCurrentAlias: 'getCurrentAlias',
      getIdentificationData: 'datos_identificacion/getData',
      datosAdjuntosGetIsSaved: 'datos_adjuntos/getIsSaved'
    }),
    /**
     * Set if the person has a legal representant
     * @param boolean if the person has legal representant
     */
    setHasLegalRepresentant(value) {
      this.hasLegalRepresentant = value;
    },
    setHasEnvironmentalTax(value) {
      this.hasEnvironmentalTax = value;
    },

    setHasISHRTax(value) {
      this.hasISHRTax = value;
    },
    setCurp(value) {
      this.curp = value;
    },
    /**
     * Validates if the tax residence component (DomicilioFiscal) is visible depending o the
     * component.alias property on the module of the flow you are actually visualising.
     *
     * @param string componentAlias
     * @return boolean returns if the component should be visible or not.
     */
    isTaxResidenceVisible(componentAlias) {
      return (
        componentAlias === 'agregar_domicilio' ||
        componentAlias === 'close_local' ||
        componentAlias === 'change_tax_residence' ||
        componentAlias === 'local_opening'
      );
    },
    /**
     * Used when clicking back on the timeline, sets the current module to the previous step in the flow,
     * if there is not other module on line when cliking previous button a
     * redirect is performed.
     *
     * @param String module
     * @return void
     */
    setPreviousModule(module) {
      this.setDisabled = true
      this.setPrevModule(module)
        .then(()=>{
          this.setDisabled = false
        })
        .catch(redirectPath => {
          this.setDisableRouteLeaveDialog({ disabled: true });
          this.$router.push(redirectPath);
        });
    },
    /** 
     * Redirect to the end page for a request process.
     * 
     * @param moduleName the name of the module to compare if it's the final page.
     * @returns void
    */
    redirectToSuccess(moduleName) {
      this.setDisabled = true;
      if (moduleName === 'vista_previa') {
          this.setDisabled = true;
          this.setDisableRouteLeaveDialog({ disabled: true });
          this.$router.push('/tramites-success');
      }
    }
  },
  computed: {
    getCurrentModuleRealTime() {
      return this.getCurrentModule() ? this.$store.state[this.getCurrentModule()].isSaved : false;
    },
    getPreackId() {
      return this.getPreacuse().data.id;
    },
    getCompanyConstitutionDate() {
      let constitutiveDate = '';
      if (
        this.getIdentificationData().data &&
        this.getIdentificationData().data.constitution_date &&
          this.getIdentificationData().data.constitution_date.length
      ) {
        constitutiveDate = this.getIdentificationData().data.constitution_date;
      }
      return constitutiveDate;
    }
  },
  watch: {
    getCurrentModuleRealTime: {
      handler() {
        this.readOnlyMode = this.$store.state[this.getCurrentModule()].isSaved;
      }
    }
  },
  components: {
    Persona,
    personaMoral,
    personaFisica,
    representante,
    actaConstitutiva,
    DomicilioFiscal,
    DatosAdjuntos,
    MediosContacto,
    SolicitudInscripcion,
    BajaRegistro,
    AumentoObligacion,
    DisminucionObligacion,
    NoticesExtraData,
    ActividadesEconomicas,
    VistaPrevia
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.wizard-tab-content {
  display: flex;
}
.wizard-tab-container {
  display: block;
  animation: slide-in 200ms ease-out forwards;
}
@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.module-description {
  margin-bottom: 60px;
}
</style>
