<template>
    <footer>
        
        <div class="contenedor_general">
            <section>
                <div class="area_navegadores" style="display: flex;  justify-content: space-evenly;  flex-wrap: wrap;">
                <div class="seccion_titulo">
                    <h3>Números de emergencia</h3>
                    <p>¿Necesitas ayuda? Estamos para servirte.</p>
                </div>
                <a class="caja_navegador" href="tel:911">
                    <span>EMERGENCIAS</span>
                    <div class="icono_caja">
                    <!-- <object id="otro_logo" class="logo_verificar" type="image/svg+xml"
                        data="../assets/img/iconos/especificos/periodico_oficial.svg"
                        title="fondo de caja login"></object> -->
                        <h1>911</h1>
                    </div>
                </a>
                <a class="caja_navegador" href="tel:089">
                    <span>DENUNCIA ANÓNIMA</span>
                    <div class="icono_caja">
                        <!-- <object id="otro_logo" class="logo_verificar" type="image/svg+xml"
                        data="../assets/img/iconos/especificos/periodico_oficial.svg"
                        title="fondo de caja login"></object> -->
                        <h1>089</h1>
                    </div>
                </a>
                <a class="caja_navegador" href="tel:070">
                    <span>ATENCIÓN CIUDADANA</span>
                    <div class="icono_caja">
                    <!-- <object id="otro_logo" class="logo_verificar" type="image/svg+xml"
                        data="../assets/img/iconos/especificos/periodico_oficial.svg"
                        title="fondo de caja login"></object> -->
                        <h1>070</h1>
                    </div>
                </a>
                <a class="caja_navegador" href="tel:073">
                    <span>AGUA Y DRENAJE</span>
                    <div class="icono_caja">
                    <!-- <object id="otro_logo" class="logo_verificar" type="image/svg+xml"
                        data="../assets/img/iconos/especificos/periodico_oficial.svg"
                        title="fondo de caja login"></object> -->
                        <h1>073</h1>
                    </div>
                </a>
                </div>
            </section>
        </div>

        <section class="seccion_enlaces">
          <div class="caja_enlaces">
            <div class="area_enlaces">
                  <div class="region region-footer-enlaces">
        <nav role="navigation" aria-labelledby="block-nlgob-footermenu1-menu" id="block-nlgob-footermenu1" class="col block block-menu navigation menu--footer-menu-1">
                
      <h5 class="visually-hidden" id="block-nlgob-footermenu1-menu">Footer Menu 1</h5>
      
    
            
                  <ul data-block="footer_enlaces" class="nav navbar-nav">
                        <li class="menu-icon menu-icon-12 nav-item">
            <a href="/es/gobierno" class="nav-link" data-drupal-link-system-path="node/41">Gobierno</a>
                  </li>
                    <li class="menu-icon menu-icon-16 nav-item">
            <a href="/es/nlinea" class="nav-link" data-drupal-link-system-path="node/15904">Trámites y servicios</a>
                  </li>
                    <li class="menu-icon menu-icon-17 nav-item">
            <a href="https://nl.gob.mx/transparencia" class="nav-link">Transparencia</a>
                  </li>
            </ul>
      
    
    
      </nav>
    <nav role="navigation" aria-labelledby="block-nlgob-footermenu2-menu" id="block-nlgob-footermenu2" class="col block block-menu navigation menu--footer-menu-2">
                
      <h5 class="visually-hidden" id="block-nlgob-footermenu2-menu">Footer Menu 2</h5>
      
    
            
                  <ul data-block="footer_enlaces" class="nav navbar-nav">
                        <li class="menu-icon menu-icon-13 nav-item">
            <a href="/es/codigodeetica" class="nav-link" data-drupal-link-system-path="node/3962">Código de ética</a>
                  </li>
                    <li class="menu-icon menu-icon-18 nav-item">
            <a href="/es/decalogo" class="nav-link" data-drupal-link-system-path="node/3969">Decálogo de principios</a>
                  </li>
            </ul>
      
    
    
      </nav>
    
      </div>
    
            </div>
            <div class="escudo_enlaces">
                <img src="https://recursos.digital.nl.gob.mx/assets/img/logos/logo_circular.svg" title="Escuedo circular de Nuevo León" alt="Escuedo circular de Nuevo León" loading="lazy">
            </div>
          </div>
        </section>
    
        <section class="seccion_redes">
          <div class="caja_redes">
            <div class="area_escudo">
                <div class="logo_secretaria"></div>
                <div class="leyenda_avisos">
                  <div class="area_privacidad col-sm-12">
                      <div class="region region-footer-privacidad">
        <nav role="navigation" aria-labelledby="block-nlgob-privacidad-menu" id="block-nlgob-privacidad" class="block block-menu navigation menu--privacidad">
                
      <h5 class="visually-hidden" id="block-nlgob-privacidad-menu">Privacidad</h5>
      
    
            
                  <ul data-block="footer_privacidad" class="nav navbar-nav">
                        <li class="menu-icon menu-icon-1 nav-item">
            <a href="/es/sobre-tratamiento-datos-personales" class="nav-link" data-drupal-link-system-path="node/4298">Sobre el tratamiento de datos personales</a>
                  </li>
            </ul>
      
    
    
      </nav>
    
      </div>
    
                  </div>
                </div>
            </div>
            <div class="area_redes">
                <div class="escudo_footer">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/logos/logo_estado_blanco.svg" title="Escuedo de Nuevo León" alt="Escuedo de Nuevo León" loading="lazy">
                </div>
                <div class="leyenda_redes">Manténte informado:</div>
                <a href="https://www.facebook.com/gobiernonuevoleon/">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/iconos/redes_sociales/facebook.svg" alt="icono de facebook">
                </a>
                <a href="https://www.instagram.com/nuevoleonmx/">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/iconos/redes_sociales/instagram.svg" alt="icono de instagram">
                </a>
                <a href="https://twitter.com/nuevoleon">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/iconos/redes_sociales/twitter.svg" alt="icono de twitter">    
                </a>
                <a href="https://t.me/gobnl">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/iconos/redes_sociales/telegram.svg" alt="icono de telegram">
                </a>
                <a href="https://www.tiktok.com/@nuevonlmx">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/iconos/redes_sociales/tiktok.svg" alt="icono de tiktok">
                </a>
                <a href="https://www.youtube.com/user/GobiernoNuevoLeon">
                    <img src="https://recursos.digital.nl.gob.mx/assets/img/iconos/redes_sociales/youtube.svg" alt="icono de youtube">
                </a>
            </div>
          </div> 
        </section>
    
        <section class="seccion_leyenda">
            <div class="primera_leyenda">Gobierno de <strong>nuevo león</strong></div>
            <div class="logo_leyenda">
                <img src="https://recursos.digital.nl.gob.mx/assets/img/logos/logo_leondorado.svg" alt="logo de león dorado">
            </div>
            <div class="segunda_leyenda">Un <strong>nuevo</strong> nuevo león</div>
        </section>
    
        <section class="seccion_licencia">
            <p>SOPORTADO POR <a class="link" href="/administracion" target="_blank">VÍA SISTEMA DE DISEÑO</a> © 2024 DE LA <a class="link" href="/administracion" target="_blank">SUBSECRETARÍA DE TECNOLOGÍAS</a>  BAJO LICENCIA <a class="link" href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank">CC BY-NC-SA 4.0</a></p>
        </section>
    </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
    name: 'Footer',
    methods: {
        /**
         * Returns from the project variables the current version of the project.
         *
         * @return string
         */
        getAppVersion() {
            return process.env.VUE_APP_VERSION;
        },
        /**
         * Returns the external nuevo leon government url.
         *
         * @return string
         */
        getNlGobExternalUrl() {
            return process.env.VUE_APP_NL_GOB_EXTERNAL_URL;
        }
    }
}
</script>

<style scoped>
footer {
    /* GOB NL BODY STYLES */
    font-family: Montserrat,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;

    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 0 auto;
    width: 100%;
    padding: 20px 0;
    z-index: -1;
}

footer .footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 96px;
    width: 80%;
}

.footer-top a {
    color: #FFF;
    text-decoration: none;
    padding: 10px;
}

.footer-top a:hover {
    color: #337ab7;
}

.footer-top a h2 {
    font-weight: 700;
    font-size: 2.0rem;
    margin: 0;
}

.footer-top a p {
    font-size: 1.2rem;
    font-weight: 500;
}

footer .footer-row {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer-row img {
    margin-right: auto;
    align-self: center;
    height: 200px;
    width: auto;
}

.footer-row #footer-outer-links {
    color: #fff;
    display: flex;
    flex-direction: row;
}

#footer-outer-links #links {
    border-right: 2px solid #FFF;
    padding-right: 15px;
}

#footer-outer-links #links .nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#footer-outer-links #links .nav li a {
    font-weight: 600;
    font-size: 1.3rem;
    color: #fff;
    display: block;
    padding: 8px 5px;
    line-height: 1;
    text-transform: uppercase;
}

#footer-outer-links #links .nav li a:hover {
    background-color: #FFF;
    color: #008aba;
}

.footer-row #footer-outer-links #social {
    padding-left: 15px;
    align-self: center;
}

.footer-row #footer-outer-links #social #social-media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    justify-content: space-between;
}

#social-media a {
    margin-right: 10px;
}

#social-media a.social__link {
    font-size: 2.5rem;
    color: #fff;
    padding: 0;
    font-weight: 400;
}

#social-media a.social__link:hover {
    color: #008aba;
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#footer-outer-links #links .nav li a {
    font-weight: 600;
    font-size: 1.3rem;
    color: #fff;
    display: block;
    padding: 8px 5px;
    line-height: 1;
    text-transform: uppercase;
}

#footer-outer-links #links .nav li a:hover {
    background-color: #FFF;
    color: #008aba;
}

#codigo-etica {
    text-decoration: none;
    color: #FFF;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}

#extra-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

#extra-info a {
    margin-right: auto;
    font-family: Roboto;
    font-size: 1.0rem;
    font-weight: 400;
    color: #fff;
    padding: 5px 9px;
}

#extra-info a:hover {
    background-color: #fff;
    color: #337ab7;
}

#extra-info span {
    font-family: Roboto;
    font-size: 1.0rem;
    font-weight: 400;
    color: #fff;
    padding: 5px 9px;
}

</style>
